import { faChevronRight } from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeActiveLayer } from '../../Redux/ApiCall/actions';
import { findLayerById, getParents } from '../../Services/Entities/Layer';

class BreadPopup extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    activeLayer: PropTypes.object.isRequired,
    projectData: PropTypes.object.isRequired,
    changeActiveLayer: PropTypes.func.isRequired,
  };

  onListItemClick = (layer) => {
    this.props.changeActiveLayer(
      findLayerById(this.props.projectData.layers, layer.id)
    );
  };

  generateList = () => {
    const parent = this.props.activeLayer.parent_id;

    if (parent === null) {
      return null;
    }

    return (
      <Breadcrumb
        separator=""
        key={this.props.activeLayer.id}
        sx={{ ol: { display: 'flex', flexWrap: 'wrap' } }}
      >
        {this.getSiblings().map((layer) => {
          return (
            <BreadcrumbItem w="100%" my="1.5" key={layer.id}>
              <BreadcrumbLink
                as="button"
                name={layer.name}
                onClick={() => this.onListItemClick(layer)}
                fontWeight={
                  layer.id === this.props.activeLayer.id ? 'bold' : 'normal'
                }
                textDecoration={
                  layer.id === this.props.activeLayer.id ? 'none' : 'underline'
                }
                isCurrentPage={this.props.activeLayer.id === layer.id}
                color={'default.txt'}
                key={layer.id}
              >
                {layer.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  };

  listBoxGenerator = () => {
    const siblings = this.generateList();
    return (
      siblings && (
        <Box my={3.5} mx={4} display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column">
            {siblings}
          </Box>
        </Box>
      )
    );
  };

  topBreadGenerator = () => {
    return (
      <Breadcrumb
        color={'primary.txt'}
        fontSize="sm"
        separator={<FontAwesomeIcon icon={faChevronRight} />}
      >
        {getParents(this.props.activeLayer, this.props.projectData.layers)
          .reverse()
          .map((layer) => {
            return (
              <BreadcrumbItem key={layer.name}>
                <BreadcrumbLink
                  as="button"
                  onClick={() => this.onListItemClick(layer)}
                  size="xs"
                >
                  {layer.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          })}
      </Breadcrumb>
    );
  };

  getSiblings() {
    const layer = findLayerById(
      this.props.projectData.layers,
      this.props.activeLayer.parent_id
    );
    return this.props.projectData.layers.filter(
      (layers) => layers.parent_id === layer.id
    );
  }

  render() {
    return (
      <Box display={this.props.active ? 'block' : 'none'}>
        <Box p={3} bg={'primary.bg.transparent'} borderRadius="2">
          <Text color={'primary.txt'} fontSize="xs" fontWeight="bold">
            Huidige Locatie
          </Text>
          <Box display="flex" alignItems="center">
            {this.topBreadGenerator()}
          </Box>
        </Box>

        {this.listBoxGenerator()}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
  projectData: state.projectData,
});

const mapDispatchToProps = {
  changeActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadPopup);
