import React from 'react';
import PropTypes from 'prop-types';
import { View } from '../Organisms/index';
import { Box, Spinner } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { loadProjectData } from '../Redux/ApiCall/actions';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { hexToRGB, lightenDarkenColor } from '../Services/Formatters/Color';

class Project extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    projectData: PropTypes.any.isRequired,
    loadProjectData: PropTypes.func.isRequired,
    theme: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      theme: extendTheme(),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.theme != prevProps.theme) {
      this.setState({
        theme: extendTheme({
          colors: {
            primary: {
              bg: {
                default: this.props.theme.primaryColor,
                transparent: hexToRGB(this.props.theme.primaryColor, 0.9),
                lighter: lightenDarkenColor(this.props.theme.primaryColor, 20),
                lightest: lightenDarkenColor(this.props.theme.primaryColor, 30),
                darker: lightenDarkenColor(this.props.theme.primaryColor, -20),
                darkest: lightenDarkenColor(this.props.theme.primaryColor, -30),
              },
              txt: this.props.theme.textOnPrimaryColor,
            },
            default: {
              txt: this.props.theme.generalTextColor,
            },
          },
          fonts: {
            googleFont: this.props.theme.googleFont,
          },
        }),
      });
    }
  }

  componentDidMount() {
    this.props.loadProjectData();
  }

  render() {
    if (this.props.loading) {
      return (
        <ChakraProvider>
          <Box
            display="flex"
            width="100vw"
            height="100vh"
            alignItems="center"
            justifyContent="center"
          >
              <Spinner size="xl" thickness="4px" />
          </Box>
        </ChakraProvider>
      );
    }

    if (this.props.error) {
      return (
        <ChakraProvider>
          <Box p={4} color={'red'}>
            ERROR: {this.props.error}
          </Box>
        </ChakraProvider>
      );
    }

    return (
      <div style={{ padding: 0 }}>
        {this.props.projectData !== false && (
          <ChakraProvider theme={this.state.theme}>
            <View />
          </ChakraProvider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projectData: state.projectData,
  activeLayer: state.activeLayer,
  loading: state.loading,
  error: state.error,
  theme: state.theme,
});

const mapDispatchToProps = {
  loadProjectData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
