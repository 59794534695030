import { Box, Container, Grid, GridItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  Bread,
  LayerNavigation,
  MobileBread,
  ViewButtons,
} from '../../Molecules/index';
import { isDesktop } from '../../Services';
import { matchesFilter } from '../../Services/Filters/Filter';
import { FilterContainer, List, Map } from '../index';

class View extends React.Component {
  static propTypes = {
    projectData: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.models = {
      layer: 'App\\Models\\Layer',
      plot: 'App\\Models\\Plot',
      popup: 'App\\Models\\Label',
      url: 'App\\Models\\Label',
    };

    this.state = {
      view: 'map',
      zoom: 1,
      plots: {},
      displayFilters: false,
      filters: {},
      filteredPlots: [],
    };

    this.views = {
      map: Map,
      list: List,
    };

    this.childStateChangeCallback = this.childStateChangeCallback.bind(this);

    this.filterButtonCallback = this.onFilterButtonClick.bind(this);
  }

  setDisplayFilter = () => {
    isDesktop && this.state.view === 'list'
      ? this.setState({ displayFilters: true })
      : this.setState({ displayFilters: false });
  };

  onViewButtonClick = (view) => {
    this.setState({ view: view }, this.setDisplayFilter);
  };

  onFilterButtonClick = (displayFilters) => {
    this.setState({ displayFilters: displayFilters }, this.setUiState);
  };

  childStateChangeCallback = (slug, data) => {
    this.setState({ [slug]: data }, () => {
      this.setState({
        filteredPlots: this.filterPlotsOnActiveFilters(),
      });
    });
  };

  filterPlotsOnActiveFilters = () => {
    return this.props.projectData.plots.filter((plot) => {
      return matchesFilter(plot, this.state.filters);
    });
  };

  render() {
    const Component = this.views[this.state.view];

    return (
      <Box>
        <Box as="header" position="relative" zIndex="2">
          <ViewButtons
            viewButtonCallback={this.onViewButtonClick}
            filterButtonCallback={this.onFilterButtonClick}
            filterDisplay={this.state.displayFilters}
            view={this.state.view}
          />

          <LayerNavigation
            projectName={this.props.projectData.name}
            view={this.state.view}
          />

          <Box pos="fixed" top={8} left={8}>
            <Bread
              projectName={this.props.projectData.name}
              view={this.state.view}
            />
          </Box>
        </Box>

        <Container
          as="main"
          centerContent="true"
          size="container.xl"
          maxW={this.state.view === 'list' ? 'container.xl' : 'none'}
          px="0"
        >
          <Grid
            gap={{
              base: 4,
              lg: 6,
              xl: 12,
            }}
            position="relative"
            zIndex="1"
            paddingX={this.state.view === 'list' ? 4 : ''}
            templateColumns="repeat(12, 1fr)"
            paddingY={this.state.view === 'list' ? 40 : ''}
            display={this.state.view === 'list' ? 'grid' : 'block'}
            w="full"
          >
            <GridItem
              colStart={1}
              position="relative"
              zIndex="2"
              colEnd={{
                base: 13,
                lg: 4,
              }}
            >
              <FilterContainer
                filters={this.props.projectData.filters}
                filterCallback={this.childStateChangeCallback}
                filterDisplay={this.state.displayFilters}
                view={this.state.view}
                activeFilters={this.state.filters}
                plots={this.props.projectData.plots}
                filteredPlots={this.state.filteredPlots}
              />
            </GridItem>

            <GridItem
              position="relative"
              zIndex="1"
              colStart={{
                base: 1,
                lg: 4,
              }}
              colEnd={{
                base: 13,
              }}
            >
              <Component
                activeFilters={this.state.filters}
                filters={this.props.projectData.filters}
                plots={this.props.projectData.plots}
                filteredPlots={this.state.filteredPlots}
                statuses={this.props.projectData.statuses}
              />
            </GridItem>
          </Grid>
        </Container>

        <MobileBread view={this.state.view} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  projectData: state.projectData,
});

export default connect(mapStateToProps)(View);
