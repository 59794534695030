import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from '@chakra-ui/react';
import { Reset } from '../../Atoms';

export default class FilterHeader extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    showResetButton: PropTypes.bool.isRequired,
    resetCallback: PropTypes.func,
    filtersHaveChanged: PropTypes.func,
  };

  render() {
    return (
        <Box as="header" display="flex" justifyContent="space-between">
          <Text fontSize="md" fontWeight="bold" as="h2">
            {this.props.label}
          </Text>

          {this.props.showResetButton &&
            <Reset
              resetCallback={this.props.resetCallback}
              filtersChanged={this.props.filtersHaveChanged}
            />
          }
        </Box>
    );
  }
}
