import { IconButton } from '@chakra-ui/button';
import PropTypes from 'prop-types';
import React from 'react';
import { isTouchDevice } from '../../Services';

import { faXmark } from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Link extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
  };

  render() {
    return (
      <IconButton
        aria-label="Popup sluiten"
        backgroundColor="transparent"
        color="inherit"
        icon={<FontAwesomeIcon icon={faXmark} />}
        onClick={this.props.callback}
        variant="link"
        _hover={{ textDecoration: !isTouchDevice ? 'none' : null }}
        px="0px"
        minWidth="0"
        fontSize="2xl"
        opacity="0.5"
      />
    );
  }
}
