import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ListItem, UnorderedList } from '@chakra-ui/react';
import { hasSameContents } from '../../Services/Arrays/Array';
import FilterHeader from './FilterHeader';

export default class QuickFilter extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onButtonClick: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props)

    this.selectAllValues = ['Te koop', 'In optie', 'Verkocht', 'Gereserveerd'];
    this.selectAvailableValues = ['Te koop', 'In optie'];
  }

  onSelectAllClick = () => {
    this.props.onButtonClick(this.selectAllValues)
  }
  
  onSelectAvailableClick = () => {
    this.props.onButtonClick(this.selectAvailableValues)
  }

  render() {
    return (
      <Box as="section" pb="5">
        <FilterHeader
          label={this.props.label}
          showResetButton={false}
        />

        <UnorderedList styleType="none" margin="0" spacing="0">
            <ListItem >
              <Button
                variant="link"
                textDecoration="underline"
                fontWeight="normal"
                onClick={this.onSelectAllClick}
                isActive={hasSameContents(this.props.selected, this.selectAllValues)}
                >
                Alle woningen
              </Button>
            </ListItem>

            <ListItem>
              <Button
                variant="link"
                textDecoration="underline"
                fontWeight="normal"
                onClick={this.onSelectAvailableClick}
                isActive={hasSameContents(this.props.selected, this.selectAvailableValues)}
                >
                Beschikbare woningen
              </Button>
            </ListItem>
        </UnorderedList>
      </Box>
    );
  }
}
