import React from 'react';
import { Box, Text, Select } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default class OrderMenu extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Box maxWidth={200} width={'full'} mb={5}>
        <Text fontWeight={700} mb={2}>
          Sorteer op
        </Text>
        <Select onChange={this.props.onChange}>
          <option value="number">Bouwnummer ↑</option>
          <option value="costDESC">Prijs ↓</option>
          <option value="costASC">Prijs ↑</option>
          <option value="type">Woningtype</option>
          <option value="livingSurfaceDESC">Woonoppervlakte ↓</option>
          <option value="livingSurfaceASC">Woonoppervlakte ↑</option>
          <option value="plotSurfaceDESC">Kaveloppervlakte ↓</option>
          <option value="plotSurfaceASC">Kaveloppervlakte ↑</option>
          <option value="status">Status</option>
        </Select>
      </Box>
    );
  }
}
