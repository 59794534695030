import { Box, Button, Center, Flex, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CheckboxFilter,
  RadioFilter,
  RangeFilter,
  StatusFilter,
} from '../../Molecules/index';
import { isDesktop, slugify } from '../../Services';

export default class FilterContainer extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    filterCallback: PropTypes.func.isRequired,
    filterDisplay: PropTypes.bool.isRequired,
    view: PropTypes.string.isRequired,
    activeFilters: PropTypes.object.isRequired,
    plots: PropTypes.array.isRequired,
    filteredPlots: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.counter = 0;

    this.filterTypes = {
      checkbox: CheckboxFilter,
      radio: RadioFilter,
      status: StatusFilter,
      range: RangeFilter,
    };

    this.state = {
      filters: {},
    };

    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
  }

  handleFilterValueChange = (slug, value) => {
    let filterState = this.state.filters;
    filterState[slug] = value;

    this.setState({ filters: filterState }, () => {
      this.props.filterCallback('filters', this.state.filters);
    });
  };

  filterStyle = () => {
    const isMobileList = this.props.view === 'list' && !isDesktop;

    return {
      position: this.props.view === 'map' || isMobileList ? 'fixed' : 'sticky',
      display: this.props.filterDisplay ? 'block' : 'none',
      paddingRight:
        isMobileList && this.props.filterDisplay ? '11.5rem' : '1rem',
    };
  };

  resetFiltersToDefaultValues = () => {
    let results = {};

    this.props.filters.forEach((filter) => {
      results[slugify(filter.slug)] = filter.default;
    });

    this.setState({ filters: results }, () => {
      this.props.filterCallback('filters', this.state.filters);
    });
  };

  render() {
    const filteredPlotsNumber = this.props.filteredPlots.length;
    const isMobileList = this.props.view === 'list' && !isDesktop;
    return (
      <>
        <Box
          style={this.filterStyle()}
          p={this.props.view === 'map' ? 6 : 4}
          backgroundColor={'#FFF'}
          boxShadow={'0 2px 4px rgb(0 0 0 / 15%)'}
          bottom={{
            base: isMobileList ? 0 : 'auto',
            xl: 'auto',
          }}
          top={{
            base: this.props.view === 'map' ? 16 : 0,
            lg: this.props.view === 'map' ? 24 : 0,
          }}
          left={{
            base: this.props.view === 'map' ? 'auto' : 0,
            xl: this.props.view === 'map' ? 'auto' : 8,
          }}
          right={{
            base: this.props.view === 'map' ? 2.5 : 'auto',
            xl: this.props.view === 'map' ? 8 : 'auto',
          }}
          maxHeight={{
            base: isMobileList
              ? 'none'
              : 'calc(100vh - var(--chakra-space-52))',
            xl: 'calc(100vh - var(--chakra-space-52))',
          }}
          overflowY="scroll"
          spacing="0"
          maxW={this.props.view === 'map' ? 80 : 'none'}
          width="full"
          zIndex={1}
        >
          <Flex pb="5" as="section" justifyContent={'space-between'}>
            <Center as="h2">
              <Text fontSize="2xl" mr="3" as="span">
                {filteredPlotsNumber}
              </Text>
              <Text as="span">
                {filteredPlotsNumber === 1 ? 'Resultaat' : 'Resultaten'}
              </Text>
            </Center>

            <Button
              variant="link"
              textDecoration="underline"
              fontWeight="normal"
              fontSize={'14px'}
              onClick={() => this.resetFiltersToDefaultValues()}
            >
              reset filters
            </Button>
          </Flex>
          {this.props.filters
            .sort((a, b) => a.order - b.order)
            .map((filter, index) => {
              if (filter.type === null) return;

              const FilterType = this.filterTypes[filter.type.type];

              return (
                <Stack direction="column" pb={5} key={index} as="section">
                  <FilterType
                    key={index}
                    values={filter.values}
                    label={filter.label}
                    onChange={this.handleFilterValueChange}
                    slug={slugify(filter.slug)}
                    default={filter.default}
                    filteredPlots={this.props.filteredPlots}
                    selected={
                      this.state.filters[slugify(filter.slug)] ?? filter.default
                    }
                  />
                </Stack>
              );
            })}
        </Box>
      </>
    );
  }
}
