import { Text } from "@chakra-ui/layout"
import React from "react"
import PropTypes from 'prop-types';

export default class SpecificationTitle extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  render() {
    return (
      <Text
        display="block"
        opacity=".5"
        textTransform="uppercase"
        pr="4"
        fontSize="xs"
        fontWeight="bold"
        lineHeight="1"
        >
          {this.props.children}
      </Text>
    )
  }
}