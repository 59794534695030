import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@chakra-ui/react';

export default class Item extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    layer: PropTypes.object,
    listItemClickHandler: PropTypes.func,
    active: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  handleClickEvent = () => {
    this.props.listItemClickHandler(this.props.layer);
  };

  render() {
    return (
      <Button
        display="inline-block"
        bgColor="transparent"
        fontWeight={this.props.active ? '600' : '400'}
        justifyContent="flex-start"
        height="auto"
        py={2}
        px={4}
        textAlign="left"
        textDecoration={this.props.active ? '' : 'underline'}
        width="100%"
        whiteSpace="inherit"
        onClick={this.handleClickEvent}
        color="default.txt"
        borderRadius={0}
        _hover={this.props.active ? {bg: 'transparent'} : {bg: 'gray.200'}}
        cursor={this.props.active ? 'default' : 'pointer'}
        _active={{bg: 'transparent'}}
      >
        {this.props.name}
      </Button>
    );
  }
}
