import { faSquareParking } from '@awesome.me/kit-2c97126699/icons/classic/solid';
import { Box, Flex, Grid, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { Fade } from '@chakra-ui/react';
import { Tag } from '@chakra-ui/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  CloseButton,
  Link,
  SpecificationTitle,
  SpecificationValue,
} from '../../Atoms/index';
import { closePopup, setActiveHotspot } from '../../Redux/ApiCall/actions';
import { price, size } from '../../Services/Formatters/Number';

class Popup extends React.Component {
  static propTypes = {
    isPopupOpen: PropTypes.bool.isRequired,
    setActiveHotspot: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
    activeHotspot: PropTypes.object,
    activeLayer: PropTypes.object,
    projectStatuses: PropTypes.object,
    plot: PropTypes.object,
  };

  animate = async () => {
    await this.props.closePopup(false);
    this.props.setActiveHotspot(
      this.props.activeLayer,
      this.props.activeHotspot
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeHotspot !== prevProps.activeHotspot) {
      this.animate();
    }

    if (this.props.activeLayer != prevProps.activeLayer) {
      this.props.closePopup(
        this.props.activeHotspot !== prevProps.activeHotspot
      );
    }
  }

  render() {
    if (this.props.isPopupOpen === false) return null;

    return (
      <Fade in={this.props.isPopupOpen}>
        <Flex
          display="flex"
          position="fixed"
          left={{ base: 2.5, md: '50%' }}
          right={{ base: 2.5, md: 'auto' }}
          bottom={{ base: 20, lg: 8 }}
          mx="auto"
          color="default.txt"
          transform={{ md: 'translateX(-50%)' }}
          justify="center"
          maxW="50rem"
          direction={{ base: 'column', md: 'row' }}
          // zIndex="100"
          zIndex="999"
          border={{ base: '3px', md: 'none' }}
          borderColor="rgba(229, 229, 229, .5)"
          borderRadius="2px"
          boxShadow="0 2px 4px rgba(0, 0, 0, .15"
        >
          {this.props.plot.media[0]?.url && (
            <Box
              background={`center center / cover no-repeat url("${this.props.plot.media[0]?.url}")`}
              flex={{ base: '1 0 auto', md: '0 0 100%' }}
              width={{ base: '100%', md: 'auto' }}
              maxW={{ md: '20rem' }}
              paddingTop={{ base: '56.25%', md: '0' }}
            />
          )}

          <Box
            backgroundColor="white"
            py={{ base: 5, md: 4 }}
            flex={{ base: '1 0 100%', md: '1 0 auto' }}
            maxW={{ md: '30rem' }}
          >
            <Grid
              className="top"
              templateColumns="repeat(4, 1fr)"
              gap="6"
              pb="3"
              borderBottom="1px solid #e5e5e5"
              px={{ base: 5, md: 3.5 }}
            >
              <GridItem
                colStart="1"
                colSpan={{ base: 4, sm: 1 }}
                colEnd={{ base: '6', sm: 2 }}
                w={{ base: '100%', sm: 'auto' }}
              >
                <Tag
                  className="status"
                  backgroundColor={
                    this.props.projectStatuses[this.props.plot.status].color
                  }
                  justifyContent="center"
                  paddingY="3"
                  lineHeight="1"
                  px="3.5"
                  h="auto"
                  minWidth={24}
                  borderRadius="2"
                  fontSize="sm"
                  fontWeight="bold"
                  w={{ base: '100%', sm: 'auto' }}
                >
                  {this.props.projectStatuses[this.props.plot.status].name}
                </Tag>
              </GridItem>

              <GridItem
                colStart={{ base: 1, sm: 2 }}
                colEnd="5"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Text
                  as="span"
                  className="cost"
                  display="block"
                  fontWeight="extrabold"
                  fontSize="lg"
                  lineHeight="1.143"
                >
                  {this.props.activeLayer.display_settings.price === '1' &&
                    this.props.plot.status !== 'verkocht' &&
                    this.props.plot.status !== 'voorbereiden-start-verkoop' &&
                    price(this.props.plot.price)}
                </Text>

                {this.props.plot.parking_count > 0 &&
                  this.props.activeLayer.display_settings
                    .parking_description === '1' && (
                    <Box
                      fontSize="sm"
                      display="flex"
                      gap={1}
                      alignItems="center"
                      opacity="50%"
                    >
                      <FontAwesomeIcon icon={faSquareParking} />
                      <Text color="default.txt">
                        {this.props.plot.parking_description}
                      </Text>
                    </Box>
                  )}

                {this.props.activeLayer.display_settings.number === '1' && (
                  <Text
                    as="span"
                    className="number"
                    display="block"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    {`Bouwnr. ${this.props.plot.number} - ${this.props.plot.type}`}
                  </Text>
                )}
              </GridItem>

              <GridItem
                colStart="5"
                colEnd="6"
                display="flex"
                justifyContent="flex-end"
              >
                <CloseButton callback={this.props.closePopup} />
              </GridItem>
            </Grid>

            <SimpleGrid
              w="100%"
              flexWrap="wrap"
              mt={{ base: 8, md: 3.5 }}
              columns={{ base: 1, md: 2 }}
              px={{ base: 5, md: 3.5 }}
            >
              <Flex direction={{ base: 'row', md: 'column' }}>
                {this.props.activeLayer.display_settings.living_surface ===
                  '1' && (
                  <Box>
                    <SpecificationTitle color="default.txt" opacity="50%">
                      Woonopp.
                    </SpecificationTitle>
                    <SpecificationValue>
                      {size(this.props.plot.living_surface)}
                      <sup>2</sup>
                    </SpecificationValue>
                  </Box>
                )}

                {this.props.activeLayer.display_settings.plot_surface ===
                  '1' && (
                  <Box mt={{ base: 0, md: 4 }}>
                    <SpecificationTitle color="default.txt" opacity="50%">
                      Kavelopp.
                    </SpecificationTitle>
                    <SpecificationValue>
                      {size(this.props.plot.plot_surface)}
                      <sup>2</sup>
                    </SpecificationValue>
                  </Box>
                )}
              </Flex>

              <Flex
                mt={{ base: 8, md: 0 }}
                align={{ base: 'flex-start', md: 'flex-end' }}
                direction="column"
                fontSize="md"
              >
                {this.props.activeLayer.display_settings.downloads_page ===
                  '1' &&
                  this.props.plot.downloads_page !== '' && (
                    <Link href={this.props.plot.downloads_page}>Downloads</Link>
                  )}
                {this.props.activeLayer.display_settings.detail_page === '1' &&
                  this.props.plot.detail_page !== '' && (
                    <Link href={this.props.plot.detail_page}>Woningtype</Link>
                  )}
                {this.props.activeLayer.display_settings.registration_page ===
                  '1' &&
                  this.props.plot.registration_page !== '' && (
                    <Link href={this.props.plot.registration_page}>
                      Inschrijven
                    </Link>
                  )}
              </Flex>
            </SimpleGrid>
          </Box>
        </Flex>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => ({
  isPopupOpen: state.isPopupOpen,
  activeHotspot: state.activeHotspot,
  activeLayer: state.activeLayer,
});

const mapDispatchToProps = {
  closePopup,
  setActiveHotspot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
