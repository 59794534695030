const preparePreload = () => {
  window.preloaded = {
    layers: {},
    plots: {},
  };
};
/**
 * Preload the background of all the child layers of layer.
 * @param {object} newActiveLayer the newly active layer
 * @param {Array} layers The layer we want to preload the children's backgrounds of.
 * @returns {void}
 */
const preloadLayerBackgrounds = (newActiveLayer, layers) => {
  if (layers === undefined) return;

  const result = layers.filter(layer => layer.parent_id === newActiveLayer.id)
    .reduce((map, obj) => {
      map[obj.background.url] = generateImage(obj.background.url);
      return map;
    }, {});

  window.preloaded.layers = { ...window.preloaded.layers, ...result };
};

/**
 * Preload all the images belonging to plot hotspots of the chosen layer.
 * @param {object} layer the newly active layer
 * @param {Array} hotspots The layer we want to preload the plot hotspot images for.
 * @param {object} plots The project data plots
 * @returns {void}
 */
const preloadPlotImages = (layer, hotspots, plots) => {
  if (hotspots === undefined) return;

  const plotHotspots = hotspots
    .filter(hotspot => hotspot.layer_id === layer.id)
    .filter((hotspot) => {
      return hotspot.entity_type === 'App\\Models\\Plot';
    });

  let filteredPlots = [];

  // TODO: refactor inefficient
  plots.forEach(plot => {
    plotHotspots.forEach(ph => {
      if (ph.entity_id === plot.id)
        filteredPlots.push(plot);
    });
  });

  const plotsWithMedia = filteredPlots.filter((plot) => {
    return plot.media.length !== 0;
  })
    .map((plot) => {
      return plot.media.map((media) => {
        return { [media.url]: generateImage(media.url) };
      });
    });

  const result = plotsWithMedia.reduce((map, images) => {
    const test = images.reduce((nestedMap, item) => {
      nestedMap[item.src] = item;
      return nestedMap;
    });
    return { ...test, ...map };
  }, {});

  window.preloaded.plots = { ...window.preloaded.plots, ...result };
};

const generateImage = (url) => {
  const img = new Image();
  img.src = url;
  return img;
};

export { preparePreload, preloadLayerBackgrounds, preloadPlotImages };