import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Popup as PlotmapPopup } from '../../Molecules/index';
import {
  changeActiveLayer,
  setActiveHotspot,
} from '../../Redux/ApiCall/actions';
import { preloadPlotImages, preparePreload } from '../../Services';
import { getKeyByValue } from '../../Services/Arrays/Array';
import './map.css';

// Leaflet
import 'leaflet';
import { CRS, LatLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {
  ImageOverlay,
  MapContainer,
  Polygon,
  ZoomControl,
} from 'react-leaflet';
import { connect } from 'react-redux';
import {
  getCoordinates,
  matches,
  parseHotspotOpacitySettings,
} from '../../Services/Entities/Hotspot';
import {
  findLayerById,
  getDescendantsRecursively,
} from '../../Services/Entities/Layer';

class Map extends React.Component {
  static propTypes = {
    activeFilters: PropTypes.object.isRequired,
    filteredPlots: PropTypes.array.isRequired,
    activeLayer: PropTypes.object.isRequired,
    projectData: PropTypes.object.isRequired,
    changeActiveLayer: PropTypes.func.isRequired,
    activeHotspot: PropTypes.object,
    setActiveHotspot: PropTypes.func.isRequired,
    statuses: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.imageOverlayRef = React.createRef();
    this.mapRef = React.createRef();
    this.backgroundImageRef = React.createRef();

    this.hotspotOpacitySettings = parseHotspotOpacitySettings(
      this.props.activeLayer
    );

    this.defaultFill = {
      fill: true,
      fillColor: '#fff',
      color: '#fff',
      stroke: '#fff',
      weight: 3,
      opacity: 1,
      className: 'hotspot',
    };

    /**
     * This will be replaced when the other hotspots have their own components
     */
    this.types = {
      plot: 'App\\Models\\Plot',
      layer: 'App\\Models\\Layer',
      url: 'App\\Models\\Url',
      label: 'App\\Models\\Label',
    };

    this.callbacks = {
      plot: this.plotClickCallback,
      url: this.urlClickCallback,
      label: this.labelClickCallback,
      layer: this.layerClickCallback,
    };

    preparePreload();
  }

  renderHotspot = (hotspot) => {
    const type = getKeyByValue(this.types, hotspot.entity_type);
    const callback = this.callbacks[type];

    const plot = this.props.projectData.plots.filter(
      (plot) => plot.id === hotspot.entity_id
    )[0];

    const pathOptions = this.setHotspotFill(hotspot, plot);

    return (
      <Polygon
        key={`${hotspot.svg}-${hotspot.id}-${pathOptions.className}`}
        pane="markerPane"
        eventHandlers={{
          click: () => {
            callback(hotspot);
          },
        }}
        positions={getCoordinates(hotspot)}
        {...pathOptions}
      />
    );
  };

  /**
   * The styling attributes for the plot hotspot.
   * @param {object} plot The plot to set the fill for.
   * @returns {object} The attributes for the plot hotspot.
   */
  setPlotHotspotFill = (plot) => {
    const activeClass =
      this.props.activeHotspot && this.props.activeHotspot?.id === plot.id
        ? 'active'
        : '';
    const matchesFiltersClass = matches(this.props.filteredPlots, plot)
      ? 'matches'
      : '';

    const status = this.props.statuses[plot.status];

    return {
      ...this.defaultFill,
      color: status.color,
      fillColor: status.color,
      className: `hotspot plot ${activeClass} ${matchesFiltersClass}`,
    };
  };

  /**
   * Set the fill of the layer hotspot based on the status of the layer's plot hotspots.
   * @param {object} hotspot The layer's hotspot
   * @returns {object} The path options for the layer's hotspot
   */
  setLayerHotspotFill = (hotspot) => {
    const hotspotLayer = this.props.projectData.layers.find(
      (layer) => layer.id === hotspot.entity_id
    );

    const children = getDescendantsRecursively(
      hotspotLayer,
      this.props.projectData.layers
    ).concat(hotspotLayer);

    const childLayerIds = children.reduce((aggregate, layer) => {
      aggregate.push(layer.id);
      return aggregate;
    }, []);

    const plotIds = this.props.projectData.hotspots.reduce(
      (aggregate, hotspot) => {
        if (!childLayerIds.includes(hotspot.layer_id)) return aggregate;

        aggregate.push(hotspot.entity_id);
        return aggregate;
      },
      []
    );

    const statuses = this.props.filteredPlots.reduce(
      (aggregate, plot) => {
        if (!plotIds.includes(plot.id)) return aggregate;

        aggregate[plot.status] = aggregate[plot.status] + 1;
        return aggregate;
      },
      {
        'te-koop': 0,
        'in-optie': 0,
        gereserveerd: 0,
        verkocht: 0,
        'voorbereiden-start-verkoop': 0,
      }
    );

    for (const status in statuses) {
      if (statuses[status] === 0) continue;

      return {
        ...this.defaultFill,
        color: this.props.projectData.statuses[status].color,
        fillColor: this.props.projectData.statuses[status].color,
        className: 'hotspot layer',
      };
    }

    return this.defaultFill;
  };

  /**
   * Set the fill of the hotspot based on the type of the hotspot.
   * @param {object} hotspot The hotspot object
   * @param {object} plot The plot object
   * @returns {object} The path options for the hotspot
   */
  setHotspotFill = (hotspot, plot) => {
    if (hotspot.entity_type === 'App\\Models\\Plot') {
      return this.setPlotHotspotFill(plot);
    }

    if (hotspot.entity_type === 'App\\Models\\Layer') {
      return this.setLayerHotspotFill(hotspot);
    }

    return this.defaultFill;
  };

  /**
   * Sets the currently active hotspot based on a click event in the hotspot components.
   * @param {object} hotspot A complete representation of the data of the clicked Hotspot component.
   */
  plotClickCallback = (hotspot) => {
    const matchedPlot = this.props.projectData.plots.filter(
      (plot) => plot.id === hotspot.entity_id
    )[0];
    this.props.setActiveHotspot(this.props.activeLayer, matchedPlot);
  };

  /**
   * Change the active layer to the layer linked to the clicked hotspot.
   * @param {object} hotspot A complete representation of the data of the clicked Hotspot component.
   */
  layerClickCallback = (hotspot) => {
    const newActiveLayer = findLayerById(
      this.props.projectData.layers,
      hotspot.entity_id
    );

    this.props.changeActiveLayer(newActiveLayer);
    this.setState({ activeHotspot: null });

    preloadPlotImages(
      newActiveLayer,
      this.props.projectData.hotspots,
      this.props.projectData.plots
    );
  };

  /**
   * Preloads the background images of all layers that are children of the current active layer.
   * This reduces delay between switching layers and being able to see the background image.
   * @param {object} currentLayer The current active layer
   * @param {object} allLayers A list of all layers in the project
   */
  lazyLoadLayerBackgrounds = (currentLayer, allLayers) => {
    allLayers
      .filter(
        (layer) =>
          layer.parent_id === currentLayer.id ||
          layer.parent_id === currentLayer.parent_id
      )
      .map((layer) => {
        const img = new Image();
        img.src = layer.background.url;
      });
  };

  labelClickCallback = () => {};

  urlClickCallback = (hotspot) => {
    window.parent.location = hotspot.url;
  };

  /**
   * Creates a LatLngBounds object based on the background image.
   * @param {object} background the API object of the background image
   * @param {number} scale the scale of the bounds, default 0.1
   * @returns {object} the LatLngBounds representation of the background image
   */
  getBounds(background, scale = 0.09) {
    return new LatLngBounds([
      [
        background.width / 2 - (background.height / 2) * scale,
        background.height / 2 - (background.width / 2) * scale,
      ],
      [
        background.width / 2 + (background.height / 2) * scale,
        background.height / 2 + (background.width / 2) * scale,
      ],
    ]);
  }

  /**
   * Change the background of the map, and reposition the layers.
   * @param {object} background Background property of the Layer object
   */
  updateMap = (background) => {
    const bounds = this.getBounds(background);

    this.imageOverlayRef.current.setUrl(background.url).setBounds(bounds);
    this.backgroundImageRef.current.setBounds(bounds.pad(0.2));
    this.mapRef.current
      .setMaxBounds(bounds.pad(0.1))
      .fitBounds(bounds, { animate: false })
      .invalidateSize();
  };

  componentDidUpdate(prevProps) {
    const { activeLayer } = this.props;
    // We only want to change the background and overlay positioning when the active layer has changed.
    if (activeLayer !== prevProps.activeLayer) {
      this.updateMap(activeLayer.background);
    }
  }

  render() {
    const bounds = this.getBounds(this.props.activeLayer.background);

    return (
      this.props.filteredPlots !== [] && (
        <Box
          className="relative w-full overflow-hidden rounded map"
          w="100%"
          sx={{
            '--hotspot-default':
              this.hotspotOpacitySettings.svg_opacity_no_filter,
            '--hotspot-matches': this.hotspotOpacitySettings.svg_opacity_filter,
            '--hotspot-active':
              this.hotspotOpacitySettings.svg_opacity_hover_active,
          }}
        >
          <Box pos={'relative'} zIndex={1}>
            <MapContainer
              crs={CRS.Simple}
              style={{ width: '100%', height: '100vh' }}
              zoom={2}
              zoomControl={false}
              zoomDelta={0.5}
              zoomSnap={0.5}
              scrollWheelZoom={false}
              doubleClickZoom={false}
              center={bounds.getCenter()}
              maxBoundsViscosity={1}
              ref={this.mapRef}
              whenReady={(map) => {
                map.target
                  .setMaxBounds(bounds.pad(0.1))
                  .fitBounds(bounds, { animate: false })
                  .invalidateSize();
              }}
            >
              <ZoomControl position="bottomright" />

              <ImageOverlay
                ref={this.backgroundImageRef}
                key={`${this.props.activeLayer.background.url}-image-background`}
                url={this.props.activeLayer.background.url}
                bounds={bounds.pad(0.2)}
                interactive={false}
                zIndex={200}
                className={'map-background'}
              />

              <ImageOverlay
                ref={this.imageOverlayRef}
                key={`${this.props.activeLayer.background.url}-image-overlay`}
                url={this.props.activeLayer.background.url}
                bounds={bounds}
                interactive={false}
                zIndex={300}
                eventHandlers={{
                  load: () => {
                    this.lazyLoadLayerBackgrounds(
                      this.props.activeLayer,
                      this.props.projectData.layers
                    );
                  },
                }}
              />

              {this.props.filteredPlots.length &&
                this.props.projectData.hotspots
                  .filter((item) => item.layer_id === this.props.activeLayer.id)
                  .map((hotspot) => this.renderHotspot(hotspot))}
            </MapContainer>
          </Box>

          <Box position="relative" zIndex={2}>
            {this.props.activeHotspot && (
              <PlotmapPopup
                projectStatuses={this.props.projectData.statuses}
                plot={this.props.activeHotspot}
              />
            )}
          </Box>
        </Box>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  projectData: state.projectData,
  activeLayer: state.activeLayer,
  activeHotspot: state.activeHotspot,
});

const mapDispatchToProps = {
  changeActiveLayer,
  setActiveHotspot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
