import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Divider } from '@chakra-ui/react';
import { ListViewItem } from '../../Atoms/index';
import { OrderMenu } from '../../Molecules/index';
import { matchesFilter } from '../../Services/Filters/Filter';
import { SortPlots } from '../../Services';

export default class List extends React.Component {
  static propTypes = {
    activeFilters: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    plots: PropTypes.array.isRequired,
    statuses: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.plots = new SortPlots();

    this.state = {
      plots: this.props.plots,
      value: 'plotNumber',
    };
  }

  /**
   * The handlechange of the order menu component
   * @param {object} event the onchange event of the order menu
   */
  handleChange(event) {
    this.setState({
      plots: this.plots.sortPlots(
        this.props.plots,
        event.target.value,
        this.props.statuses
      ),
    });
  }

  componentDidMount() {
    this.setState({
      plots: this.plots.sortPlots(
        this.props.plots,
        'number',
        this.props.statuses
      ),
    });
  }

  render() {
    return (
      <Box w="100%">
        <OrderMenu onChange={this.handleChange.bind(this)} />
        <Divider />
        <VStack alignItems="initial" spacing={0}>
          {this.state.plots.map((plot, index) => {
            return (
              matchesFilter(plot, this.props.activeFilters) && (
                <ListViewItem
                  key={`${index}-${plot.entity_id}_${plot.entity_type}`}
                  plot={plot}
                  filters={this.props.filters}
                  activeFilters={this.props.activeFilters}
                  statuses={this.props.statuses}
                />
              )
            );
          })}
        </VStack>
      </Box>
    );
  }
}
