import React from 'react';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Base from './Base';
import FilterHeader from './FilterHeader';
import { groupFilteredPlots } from '../../Services';

export default class CheckboxFilter extends Base {
  static propTypes = {
    values: PropTypes.array.isRequired,
    default: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    filteredPlots: PropTypes.array,
  };

  handleChange = (event) => {
    this.props.onChange(this.props.slug, event);
  };

  /**
   * Returns the formatted display label & plot count.
   * @param {string} value The name of the filter.
   * @returns {string} The label to be displayed & the plot count.
   */
  generatePlotCount = (value) => {
    const houseTypeArray = groupFilteredPlots(this.props.filteredPlots, 'type');

    return `${value} (${
      houseTypeArray[value] != undefined || false
        ? houseTypeArray[value].length
        : 0
    })`;
  };

  /**
   * Maps over the Woningtypes & generates checkbox filters.
   * Each checkbox filter displays the amount of available plots that match the filter.
   * @returns {React.Component} The checkbox filter components.
   */
  renderCheckbox = () => {
    return this.props.values.map((value) => {
      return (
        <Checkbox key={value} value={value}>
          {this.generatePlotCount(value)}
        </Checkbox>
      );
    });
  };

  render() {
    return (
      <>
        <FilterHeader
          label={this.props.label}
          resetCallback={this.resetCallback}
          filtersHaveChanged={this.filtersHaveChanged}
          showResetButton={true}
        />

        <CheckboxGroup
          name={this.props.slug}
          defaultValue={this.props.default}
          value={this.props.selected}
          onChange={this.handleChange}
        >
          {this.renderCheckbox()}
        </CheckboxGroup>
      </>
    );
  }
}
