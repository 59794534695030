import { Text } from "@chakra-ui/layout"
import React from "react"
import PropTypes from 'prop-types';

export default class SpecificationValue extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  render() {
    return (
      <Text
        display="block"
        fontSize="lg"
        >
          {this.props.children}
      </Text>
    )
  }
}