export default class SortPlots {
  /**
   * Order all plots based on the sorting type.
   * @param {Array} plots All plots to sort.
   * @param {string} sortType The type of sorting to use.
   * @param {Array} statuses The project's plots statuses.
   * @returns {Array} A list of sorted plots.
   */
  sortPlots = (plots, sortType, statuses) => [...plots].sort((a, b) => this.sortType(a, b, sortType, statuses));

  /**
   * Run asc/descending sorting based on the type of sorting required.
   * Note: we have to format the values before sending it to the sorting method.
   * @param {object} a Plot A
   * @param {object} b Plot B
   * @param {string} sortType Type of sorting we want to do
   * @param {Array} statuses The projects statuses
   * @returns {number} 1, -1 or 0
   */
  sortType = (a, b, sortType, statuses) => {
    if (sortType === 'number') return this.ascending(a.number.toString(), b.number.toString());
    if (sortType === 'type') return this.ascending(a.type, b.type);
    if (sortType === 'costASC' ) return this.sortPlotsByAscCost(a, b);
    if (sortType === 'costDESC' ) return this.sortPlotsByDescCost(a, b);
    if (sortType === 'livingSurfaceASC') return this.ascending(a.living_surface.toString(), b.living_surface.toString());
    if (sortType === 'livingSurfaceDESC') return this.descending(a.living_surface.toString(), b.living_surface.toString());
    if (sortType === 'plotSurfaceASC' ) return this.ascending(a.plot_surface.toString(), b.plot_surface.toString());
    if (sortType === 'plotSurfaceDESC') return this.descending(a.plot_surface.toString(), b.plot_surface.toString());
    if (sortType === 'status') return this.ascending(statuses[a.status].order.toString(), statuses[b.status].order.toString());

    return null
  }
 
  /**
   * ASCENDING: Puts the lowest value on top.
   * @param {object} a Plot A
   * @param {object} b Plot B
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
   * @returns {number} 1, -1 or 0
   */
  ascending = (a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'})

  /**
   * DESCENDING: Puts the highest value on top.
   * @param {object} a Plot A
   * @param {object} b Plot B
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
   * @returns {number} 1, -1 or 0
   */
  descending = (a, b) => b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'})

  /**
   * Puts the lowest value on top.
   * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
   * Continues to sort as usual.
   * @param {object} a Plot A
   * @param {object} b Plot B
   * @returns {number} 1, -1 or 0
   */
  sortPlotsByAscCost = (a, b) => {
    if (a.status === 'verkocht' || a.status === 'voorbereiden-start-verkoop') {
      return 1;
    } else if (b.status === 'verkocht' || b.status === 'voorbereiden-start-verkoop') {
      return -1;
    }

    return a.price.toString().localeCompare(b.price.toString(), undefined, {numeric: true, sensitivity: 'base'})
  }

  /**
   * Puts the highest value on top.
   * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
   * Continues to sort as usual.
   * @param {object} a Plot A
   * @param {object} b Plot B
   * @returns {number} 1, -1 or 0
   */
  sortPlotsByDescCost = (a, b) => {
    if (a.status === 'verkocht'  || a.status === 'voorbereiden-start-verkoop') {
      return 1;
    } else if (b.status === 'verkocht' || b.status === 'voorbereiden-start-verkoop') {
      return -1;
    }

    return b.price.toString().localeCompare(a.price.toString(), undefined, {numeric: true, sensitivity: 'base'})
  }
}
