import { Link as ChakraLink, Text } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import React from 'react';
import { isTouchDevice } from '../../Services';

import { faArrowRight } from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Link extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  };

  render() {
    return (
      <ChakraLink
        href={this.props.href}
        size="base"
        lineHeight="8"
        display="block"
        target="_parent"
        textDecoration="underline"
        _hover={{ textDecoration: !isTouchDevice ? 'none' : null }}
      >
        <Text as="span" mr="3">
          {this.props.children}
        </Text>
        <Text as="span" fontSize="xs">
          <FontAwesomeIcon icon={faArrowRight} />
        </Text>
      </ChakraLink>
    );
  }
}
