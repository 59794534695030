import {
  faArrowCircleLeft,
  faChevronRight,
  faHouse,
} from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeActiveLayer } from '../../Redux/ApiCall/actions';
import { isDesktop } from '../../Services';
import { findLayerById, getParents } from '../../Services/Entities/Layer';
import BreadcrumbButton from './BreadcrumbButton';

class Bread extends React.Component {
  static propTypes = {
    activeLayer: PropTypes.object.isRequired,
    projectData: PropTypes.object.isRequired,
    changeActiveLayer: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
  };

  onListItemClick = (layerId) => {
    this.props.changeActiveLayer(
      findLayerById(this.props.projectData.layers, layerId)
    );
  };

  onBackButtonClick = () => {
    const target = this.props.activeLayer.parent_id;
    if (target !== null) {
      this.onListItemClick(target);
    }
  };

  topBreadGenerator = () => (
    <Box display="flex">
      <Breadcrumb
        color={'primary.txt'}
        size="sm"
        separator={<FontAwesomeIcon icon={faChevronRight} opacity="0.5" />}
      >
        {getParents(this.props.activeLayer, this.props.projectData.layers)
          .reverse()
          .map((layer) => (
            <BreadcrumbItem key={layer.id}>
              <BreadcrumbLink
                key={layer.name}
                as="button"
                onClick={() => this.onListItemClick(layer.id)}
              >
                {layer.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
      </Breadcrumb>
    </Box>
  );

  render() {
    return (
      isDesktop &&
      this.props.view === 'map' && (
        <Box
          display={'flex'}
          alignItems="stretch"
          bg={'primary.bg.transparent'}
          w="auto"
          backdropFilter={'blur(4px)'}
          borderRadius="2px"
        >
          <Box display="flex" flexDirection="column">
            <BreadcrumbButton
              label="Begin"
              icon={<FontAwesomeIcon icon={faHouse} />}
              clickCallback={() => {
                this.onListItemClick(this.props.projectData.layers[0].id);
              }}
              color={'primary.txt'}
              disabled={
                this.props.activeLayer.id ===
                this.props.projectData.layers[0].id
              }
            />

            <Divider
              orientation="horizontal"
              opacity="0.1"
              ml={2}
              mr={2}
              width="auto"
              borderColor={'primary.txt'}
            />

            <BreadcrumbButton
              label="Vorige"
              icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
              clickCallback={() => {
                this.onBackButtonClick();
              }}
              color={'primary.txt'}
              disabled={
                this.props.activeLayer.id ===
                this.props.projectData.layers[0].id
              }
            />
          </Box>

          <Divider
            orientation="vertical"
            borderColor={'primary.txt'}
            height="auto"
            my="2.5"
            opacity="0.1"
          />

          <Box
            px={5}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Text color={'primary.txt'} fontSize="xs" fontWeight={'bold'}>
              Je bekijkt nu:
            </Text>
            {this.topBreadGenerator()}
          </Box>
        </Box>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
  projectData: state.projectData,
  allLayers: state.allLayers,
});

const mapDispatchToProps = {
  changeActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bread);
