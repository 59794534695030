import {
  faFilter as faRegularFilter,
  faList as faRegularList,
  faMap as faRegularMap,
} from '@awesome.me/kit-2c97126699/icons/classic/regular';
import {
  faMap as faMapSolid,
  faFilter as faSolidFilter,
  faList as faSolidList,
} from '@awesome.me/kit-2c97126699/icons/classic/solid';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closePopup } from '../../Redux/ApiCall/actions';
import { isDesktop } from '../../Services';

class ViewButtons extends React.Component {
  static propTypes = {
    viewButtonCallback: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
    filterButtonCallback: PropTypes.func.isRequired,
    filterDisplay: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
  };

  toggleMapView = () => {
    this.props.viewButtonCallback('map');
  };

  toggleListView = () => {
    this.props.closePopup();
    this.props.viewButtonCallback('list');
  };

  toggleFilters = () => {
    this.props.filterButtonCallback(this.props.filterDisplay === false);
  };

  shouldDisplay = () => {
    const view = this.props.view === 'list' ? 'none' : 'inline-flex';
    return isDesktop ? view : 'inline-flex';
  };

  render() {
    return (
      <Box
        position="fixed"
        right={{ base: 2.5, lg: 8 }}
        top={{ base: 2.5, lg: 8 }}
        display="flex"
        gap="2.5"
      >
        <ButtonGroup
          spacing="0"
          padding="2px"
          boxShadow="0 2px 4px rgba(0, 0, 0, .15)"
          bg="white"
        >
          <Button
            leftIcon={
              <FontAwesomeIcon
                icon={this.props.view === 'map' ? faMapSolid : faRegularMap}
              />
            }
            fontSize="sm"
            iconSpacing={isDesktop ? 1 : 0}
            background="transparent"
            border="0"
            borderRadius="2px"
            onClick={this.toggleMapView}
            color={'default.txt'}
            isActive={this.props.view === 'map'}
            _active={{
              bg: 'primary.bg.transparent',
              color: 'primary.txt',
            }}
          >
            {isDesktop ? 'Kaart' : ''}
          </Button>
          <Button
            leftIcon={
              <FontAwesomeIcon
                icon={this.props.view === 'list' ? faSolidList : faRegularList}
              />
            }
            fontSize="sm"
            iconSpacing={isDesktop ? 1 : 0}
            background="transparent"
            borderRadius="2px"
            onClick={this.toggleListView}
            color={'default.txt'}
            isActive={this.props.view === 'list'}
            _active={{
              bg: 'primary.bg.transparent',
              color: 'primary.txt',
            }}
          >
            {isDesktop ? 'Lijst' : ''}
          </Button>
        </ButtonGroup>

        <ButtonGroup
          spacing="0"
          padding="2px"
          boxShadow="0 2px 4px rgba(0, 0, 0, .15)"
          bg="white"
          display={this.shouldDisplay}
        >
          <Button
            leftIcon={
              <FontAwesomeIcon
                icon={
                  this.props.filterDisplay ? faSolidFilter : faRegularFilter
                }
              />
            }
            fontSize="sm"
            iconSpacing={isDesktop ? 1 : 0}
            borderRadius="2px"
            onClick={this.toggleFilters}
            isActive={this.props.filterDisplay}
            color="default.txt"
            bg="white"
            _active={{
              bg: 'primary.bg.transparent',
              color: 'primary.txt',
            }}
          >
            {isDesktop ? 'Filters' : ''}
          </Button>
        </ButtonGroup>
      </Box>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewButtons);
