/**
 * Recursively generates an array of all layers in the project
 * @param {object} parent The parent layer to get the descendents of
 * @param {Array} allLayers All layers in the project
 * @returns {Array} Array of all the layers in the project
 */
const getDescendantsRecursively = (parent, allLayers) => {
  const children = allLayers.filter((item) => parent.id === item.parent_id);

  if (children.length === 0) {
    return [];
  }

  return children.concat(
    children
      .map((child) => getDescendantsRecursively(child, allLayers))
      .filter((child) => child.length > 0)
  );
};

/**
 * Filters all layers to return a Layer with a specific ID
 * @param {Array} layers An array of layers
 * @param {number} id The ID of the layer we want to get
 * @returns {object} the Layer with a matching ID
 */
const findLayerById = (layers, id) => {
  return layers.filter((layer) => layer.id === id)[0];
};

/**
 * Returns all the parent layers of `layer` by recursively matching the parent_id property, result includes `layer` itself
 * @param {object} layer Layer we want to get the parents of
 * @param {Array} layers Array of all layers that we are searching in
 * @returns {Array} An array of all the parents of `layer` including `layer` itself
 */
const getParents = (layer, layers) => {
  return !layer.parent_id
    ? [layer]
    : [layer].concat(
        getParents(findLayerById(layers, layer.parent_id), layers)
      );
};

const getStatusColor = (hotspot, projectData) => {
  if (!hotspot) return { color: 'white' };

  switch (hotspot.entity_type) {
    case 'App\\Models\\Layer':
      return getLayerHotspotColor(
        hotspot,
        projectData.layers,
        projectData.plots,
        projectData.hotspots,
        projectData.statuses
      );
    case 'App\\Models\\Plot':
      return getPlotHotspotColor(
        hotspot,
        projectData.plots,
        projectData.statuses
      );
    default:
      return { color: 'white' };
  }
};

/**
 * @param {object} hotspot The hotspot we want to get the color for
 * @param {Array} layers All layers in the project
 * @param {Array} plots All plots in the project
 * @param {Array} hotspots All hotspots in the project
 * @param {Array} statuses All statuses in the project
 * @returns {object} The Status object with the color property
 */
const getLayerHotspotColor = (hotspot, layers, plots, hotspots, statuses) => {
  const layer = findLayerById(layers, hotspot.entity_id);

  const descendants = [
    // We need to add the current layer to the list as well,
    // or we won't include plots on the layer linked to the current hotspot
    layer,
    ...getDescendantsRecursively(layer, layers),
  ]
    .map((layer) => layer.id)
    .filter((id) => id !== undefined);

  const plotIds = hotspots
    // All plot hotspots
    .filter((hotspot) => hotspot.entity_type === 'App\\Models\\Plot')
    // On any of the decendant layers
    .filter((hotspot) => descendants.includes(hotspot.layer_id))
    // Grab only the plot id
    .map((plotHotspot) => plotHotspot.entity_id);

  const plotStatuses = plots
    // Only the plots on descendant layers
    .filter((plot) => plotIds.includes(plot.id))
    // Grab only the status
    .map((plot) => plot.status);

  // Remove duplicate statuses
  const uniqueStatuses = [...new Set(plotStatuses)];

  if (uniqueStatuses.includes('te-koop')) {
    return statuses['te-koop'];
  } else if (uniqueStatuses.includes('in-optie')) {
    return statuses['in-optie'];
  } else if (uniqueStatuses.includes('gereserveerd')) {
    return statuses['gereserveerd'];
  } else if (uniqueStatuses.includes('verkocht')) {
    return statuses['verkocht'];
  } else if (uniqueStatuses.includes('voorbereiden-start-verkoop')) {
    return statuses['voorbereiden-start-verkoop'];
  } else {
    return { color: 'white' };
  }
};

/**
 * @param {object} hotspot The hotspot we want to get the color for
 * @param {Array} plots All plots in the project
 * @param {Array} statuses All possible plot statuses
 * @returns {object} The Status object with the color property
 */
const getPlotHotspotColor = (hotspot, plots, statuses) => {
  const plot = plots.filter((plot) => plot.id === hotspot.entity_id);
  return statuses[plot.status];
};

export { findLayerById, getDescendantsRecursively, getParents, getStatusColor };
