import { getParents } from '../../Services/Entities/Layer';

/**
 * This method is responsible for matching the active layer/hotspot to the active url slug.
 * @param {Array} layers an array of all project layers
 * @returns {object} the active layer or hotspot that matches the url slug
 */
export const getActiveEntityFromSlug = (layers) => {
  const paths = window.location.pathname.split('/')
  
  const lastPathSegment = paths[paths.length - 1]
  const isOverviewLayer = lastPathSegment === ''
  if (isOverviewLayer) {
    return {
      layer: layers[0],
      hotspot: null
    }
  }

  const matchingLayers = layers.filter(layer => layer.slug === lastPathSegment)
  const isLayer = matchingLayers.length > 0
  if (isLayer) {
    return {
      layer: matchingLayers[0],
      hotspot: null
    }
  }

  const layerPathSegment = paths[paths.length - 2]
  const hotspotLayers = layers.filter(layer => layer.slug === layerPathSegment)

  const matchingHotspots = hotspotLayers[0]
    .hotspots
    .filter(hotspot => hotspot.entity_type === "App\\Models\\Plot")
    .filter((hotspot) => hotspot.entity.fields.number === lastPathSegment).length > 0

  const isHotspot = matchingHotspots.length > 0
  if (isHotspot) {
    return {
      layer: hotspotLayers[0],
      hotspot: matchingHotspots[0]
    }
  }

  return {
    layer: layers[0],
    hotspot: null
  }
}

/**
 * Generate a slug based on the hierarchical structure of the layers.
 * Note: we don't want the 'overzicht' layer slug in the URL because that is our top-level layer.
 * @param {object} layer The layer we want to generate the slug for.
 * @param {Array} layers All layers that we are searching
 * @returns {string} the full slug of the layer we're viewing.
 */
export const generateLayerSlug = (layer, layers) => {
  // We don't want to show a slug when we're on the top-level layer.
  if (layer.slug === 'overzicht') return '/'

  return getParents(layer, layers)
    // We don't want to add the 'overzicht' slug in the URL here either.
    .map((parent) => parent.slug === 'overzicht' ? '' : parent.slug)
    // Reverse the whole array structure so that the top-level layer's slug is shown first.
    .reverse()
    // Join the slugs by a '/' to turn it into a valid path.
    .join('/')
}

/**
 * Generate a slug based on the hierarchical structure of the layers.
 * Note: we don't want the 'overzicht' layer slug in the URL because that is our top-level layer.
 * @param {object} layer The layer we want to generate the slug for.
 * @param {Array} layers All layers that we are searching
 * @param {object} plot The object containing the fields we need.
 * @returns {string} the full slug of the layer we're viewing.
 */
export const generatePlotSlug = (layer, layers, plot) => {
  const layerPath = generateLayerSlug(layer, layers)
  return `${layerPath}/${plot.number}`
}

/**
 * This method is reposible for determining if the plot slug should be removed from the url or not.
 * @param {boolean} shouldRemovePlotSlug whether it should or not remove the slug from the plot.
 * @returns {string} the modified or unmodified url slug
 */
export const removePlotSlugFromPath = (shouldRemovePlotSlug) => {
  if (! shouldRemovePlotSlug) return window.location.pathname

  const paths = window.location.pathname.split('/')
  paths.pop()
  return paths.join('/')
}