/**
 * Generates a rgba color from a hex color with an alpha value
 * @param {string} hex Hexadecimal color value
 * @param {string} alpha Alpha value
 * @returns {string} rgba color value
 */
const hexToRGB = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

/**
 * Lightens or darken a hex color.
 * https://css-tricks.com/snippets/javascript/lighten-darken-color/
 * @param {string} color The hex color to be modified
 * @param {number} amount The amount we want to modify it by. Negative values darken the color, positive values lighten it.
 * @returns {string} The modified color
 */
const lightenDarkenColor = (color, amount) => {
  let usePound = false;

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export { hexToRGB, lightenDarkenColor };
