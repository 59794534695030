import PropTypes from 'prop-types';
import React from 'react';
import LayerItem from '../../Atoms/LayerNavigation/Item';

import { Box, ListItem, OrderedList } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { changeActiveLayer } from '../../Redux/ApiCall/actions';
import { findLayerById } from '../../Services/Entities/Layer';
import {isDesktop} from '../../Services'

class LayerNavigation extends React.Component {
  static propTypes = {
    activeLayer: PropTypes.object.isRequired,
    projectName: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    changeActiveLayer: PropTypes.func.isRequired,
    projectData: PropTypes.object.isRequired,
    allLayers: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: true,
      height: 64,
    };
  }

  updateListSize = () => {
    this.setState({ height: this.state.height === 64 ? '75vh' : 64 });
  };

  onListItemClick = (layer) => {
    this.props.changeActiveLayer(layer);
  };

  generateList = () => {
    const parent = this.props.activeLayer.parent_id
    
    if (parent === null) {
      return null;
    }

    const layer = findLayerById(this.props.allLayers, this.props.activeLayer.parent_id)
    const siblings = this.props.allLayers.filter(layers => layers.parent_id === layer.id)

    return (
      <OrderedList
        align="stretch"
        direction="row"
        height={this.state.height}
        margin="0"
        spacing="0"
        styleType="none"
        py={1}
      >
        {siblings.map((layer) => {
          return (
            <ListItem w="100%" key={layer.id}>
              <LayerItem
                name={layer.name}
                layer={layer}
                listItemClickHandler={this.onListItemClick}
                active={layer.id === this.props.activeLayer.id}
              />
            </ListItem>
          );
        })}
      </OrderedList>
    );
  };

  shouldDisplay = () => {
    return this.props.activeLayer?.parent_id && isDesktop
  }

  render() {
    return this.shouldDisplay() && (
      <Box
        position="fixed"
        left="8"
        top="50%"
        transform="translateY(-50%)"
        backgroundColor="white"
        overflow="hidden auto"
        width="40"
        display={this.props.view === 'map' ? 'block' : 'none'}
        border="1px"
        borderColor="rgba(229, 229, 229, .5)"
        borderRadius="2px"
        boxShadow="0 2px 4px rgba(0, 0, 0, .15)"
      >
        {this.state.open && (
          <Box>
            {this.generateList()}
          </Box>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
  projectData: state.projectData,
  allLayers: state.allLayers,
});

const mapDispatchToProps = {
  changeActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerNavigation);
