import React from 'react';
import { price, size } from '../../Services/Formatters/Number';
import Base from './Base';
import FilterHeader from './FilterHeader';
import {
  Text,
  Tooltip,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react'

export default class RangeFilter extends Base {
  handleChange = (value) => {
    const valuesStructure = {
      min: value[0],
      max: value[1],
      stepSize: this.props.default.stepSize,
      steps: this.props.default.steps,
    };

    this.props.onChange(this.props.slug, valuesStructure);
  };

  isPriceFilter = () => {
    return this.props.slug === 'price';
  };

  formatValueLabel = (number) => {
    if (this.isPriceFilter()) {
      return price(number);
    }

    return (
      <span>
        {size(number)}
        <sup>2</sup>
      </span>
    );
  };

  render() {
    return (
      <>
        <FilterHeader
          label={this.props.label}
          resetCallback={this.resetCallback}
          filtersHaveChanged={this.filtersHaveChanged}
          showResetButton={true}
        />

        <Text fontSize="sm">
          <span>{this.formatValueLabel(this.props.selected.min)}</span>
          <span> - </span>
          <span>{this.formatValueLabel(this.props.selected.max)}</span>
        </Text>

        <RangeSlider 
          aria-label={['min', 'max']} 
          value={[this.props.selected.min, this.props.selected.max]} 
          min={this.props.values.min} 
          max={this.props.values.max} 
          step={this.props.default.stepSize} 
          minStepsBetweenThumbs={1}
          onChange={this.handleChange} 
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack bg="primary.bg.default" />
          </RangeSliderTrack>
          <Tooltip hasArrow placement='top' label={this.formatValueLabel(this.props.selected.min)}>
            <RangeSliderThumb index={0} bg={'#FFF'} boxShadow='0 2px 4px rgb(0 0 0 / 30%)' />
          </Tooltip>
          <Tooltip hasArrow placement='top' label={this.formatValueLabel(this.props.selected.max)}>
            <RangeSliderThumb index={1} bg={'#FFF'} boxShadow='0 2px 4px rgb(0 0 0 / 30%)'/>
          </Tooltip>
        </RangeSlider>
      </>
    );
  }
}
