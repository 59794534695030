import { faArrowLeft } from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeActiveLayer } from '../../Redux/ApiCall/actions';

class MobileBread extends React.Component {
  static propTypes = {
    projectName: PropTypes.string,
    view: PropTypes.string,
    activeLayer: PropTypes.object.isRequired,
    projectData: PropTypes.object.isRequired,
    changeActiveLayer: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onListItemClick = (layer) => {
    this.props.changeActiveLayer(layer);
  };

  generateList = (layers) => (
    <Box display="flex">
      {layers.map((layer) => (
        <Breadcrumb separator=">" key={layer.id}>
          <BreadcrumbItem w="100%" px="2" key={layer.id}>
            <BreadcrumbLink
              as="button"
              name={layer.name}
              layer={layer}
              onClick={() => this.onListItemClick(layer)}
              active={layer.id === this.props.activeLayer.id}
              color={'primary.txt'}
            >
              {layer.name}
            </BreadcrumbLink>

            {layer?.children?.length > 0 && this.generateList(layer.children)}
          </BreadcrumbItem>
        </Breadcrumb>
      ))}
    </Box>
  );

  render() {
    return (
      <Box>
        <Box display="flex" p={4} bg={'primary.bg.transparent'}>
          <Button m={1} leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
            {' '}
            Vorige
          </Button>

          <Box mx={10}>
            <Heading size="sm" color={'primary.txt'}>
              Je Bekijkt Nu:
            </Heading>

            <Heading size="md" m={1} color={'primary.txt'}>
              {this.props.activeLayer.name}
            </Heading>
          </Box>

          {this.state.isOpen ? (
            <Button onClick={() => this.setState({ isOpen: false })}>⬆︎</Button>
          ) : (
            <Button onClick={() => this.setState({ isOpen: true })}>⬇︎</Button>
          )}

          <Popover
            isOpen={this.state.isOpen}
            onClose={() => this.setState({ isOpen: false })}
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Button bg={'primary.bg.default'}></Button>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverHeader fontWeight="semibold">
                <Heading mb={5} size="sm">
                  Huidige Locatie
                </Heading>
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                {this.generateList(this.props.projectData.layers)}
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <Box my={8} mx={4} display="flex" flexDirection="column">
                  {this.props.projectData.layers.map((layer) =>
                    this.generateList(layer.children)
                  )}
                </Box>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
  projectData: state.projectData,
});

const mapDispatchToProps = {
  changeActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileBread);
