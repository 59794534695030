import {
  faDownload,
  faFileSignature,
  faHome,
} from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { faVectorSquare } from '@awesome.me/kit-2c97126699/icons/classic/thin';
import {
  Box,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Kaveloppervlakte } from '../../Assets/Kaveloppervlakte.svg';
import { price, size } from '../../Services/Formatters/Number';

class Item extends React.Component {
  static propTypes = {
    plot: PropTypes.object.isRequired,
    activeFilters: PropTypes.object.isRequired,
    activeLayer: PropTypes.object.isRequired,
    statuses: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Stack
        direction={{ base: 'column', xl: 'row' }}
        justifyContent={{ xl: 'space-between' }}
        spacing={{ base: 5, xl: 0 }}
        py="5"
        borderBottom="1px solid #e2e8f0"
        alignItems={{ base: 'flex-start', xl: 'center' }}
      >
        {/* GeneralPlotInfoSection */}
        <SimpleGrid
          className="top"
          columns={{ base: 1, xl: 2 }}
          display={{ xl: 'flex' }}
          gap={{ xl: '3' }}
          flex={{ xl: '0 0 auto' }}
          alignItems="center"
        >
          <Tag
            className="status"
            display="flex"
            textAlign="center"
            justifyContent="center"
            paddingY="4"
            width="32"
            h="12"
            m={{ base: 0, xl: 2 }}
            backgroundColor={this.props.statuses[this.props.plot.status].color}
            fontSize={{ base: 'md', xl: 'sm' }}
          >
            {this.props.statuses[this.props.plot.status].name}
          </Tag>
          <Box
            d="flex"
            flexDirection={'column'}
            justifyContent="center"
            marginTop={{ base: 3, xl: 0 }}
          >
            {this.props.activeLayer.display_settings.price === '1' &&
              this.props.plot.status !== 'verkocht' &&
              this.props.plot.status !== 'voorbereiden-start-verkoop' && (
                <Text
                  as="span"
                  className="cost"
                  color="gray.900"
                  display="block"
                  fontWeight="extrabold"
                  fontSize={{ base: 'xl', xl: 'md' }}
                >
                  {price(this.props.plot.price)}
                </Text>
              )}

            {this.props.activeLayer.display_settings.number === '1' && (
              <Text
                as="span"
                className="number"
                color="gray.800"
                display="block"
                fontSize={'md'}
              >
                Bouwnr. {this.props.plot.number} - {this.props.plot.type}
              </Text>
            )}
            <Text color="default.txt" opacity="50%" w="xs">
              {this.props.plot.parking_count > 0 &&
                this.props.plot.parking_description}
            </Text>
          </Box>
        </SimpleGrid>

        {/* SurfaceSection */}
        <SimpleGrid
          alignItems={{ xl: 'flex-end' }}
          className="middle"
          columns={{ base: 1, xl: 2 }}
          gap={{ base: 0, xl: 8 }}
          fontSize="sm"
          flex={{ xl: '0 0 auto' }}
        >
          {this.props.activeLayer.display_settings.living_surface === '1' && (
            <Box
              className="livingSurface"
              display="flex"
              flexDirection={{ lg: 'column', xl: 'row' }}
            >
              <Flex gap="2" alignItems={'center'}>
                <FontAwesomeIcon icon={faVectorSquare} />

                <Text className="value" as="span">
                  {size(this.props.plot.living_surface)}
                  <sup>2</sup>
                </Text>
                <Text
                  className="label"
                  color="gray.600"
                  opacity=".5"
                  marginRight="4"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  as="span"
                >
                  wonen
                </Text>
              </Flex>
            </Box>
          )}

          {this.props.activeLayer.display_settings.plot_surface === '1' && (
            <Box
              className="plotSurface"
              display="flex"
              flexDirection={{ lg: 'column', xl: 'row' }}
            >
              <Flex gap="2" alignItems={'center'}>
                <Kaveloppervlakte />
                <Text className="value" as="span">
                  {size(this.props.plot.plot_surface)}
                  <sup>2</sup>
                </Text>
                <Text
                  className="label"
                  color="gray.600"
                  opacity=".5"
                  marginRight="4"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  as="span"
                >
                  kavel
                </Text>
              </Flex>
            </Box>
          )}
        </SimpleGrid>

        {/* DownloadsSection */}
        <Box
          className="bottom"
          alignItems={{ xl: 'flex-end' }}
          display={{ xl: 'flex' }}
          flex={{ xl: '0 0 auto' }}
        >
          {this.props.activeLayer.display_settings.detail_page === '1' && (
            <Link href={this.props.plot.detail_page} target="_parent">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          )}
          {this.props.activeLayer.display_settings.downloads_page === '1' && (
            <Link
              href={this.props.plot.downloads_page}
              marginLeft="5"
              target="_parent"
            >
              <FontAwesomeIcon icon={faDownload} />
            </Link>
          )}
          {this.props.activeLayer.display_settings.registration_page ===
            '1' && (
            <Link
              href={this.props.plot.registration_page}
              marginLeft="5"
              target="_parent"
            >
              <FontAwesomeIcon icon={faFileSignature} />
            </Link>
          )}
        </Box>
      </Stack>
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
