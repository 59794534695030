import { faXmark } from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default class Reset extends React.Component {
  static propTypes = {
    resetCallback: PropTypes.func.isRequired,
    filtersChanged: PropTypes.func.isRequired,
  };

  onResetButtonClick = () => {
    this.props.resetCallback();
  };

  render() {
    return (
      <IconButton
        aria-label="Reset filter"
        size="xs"
        variant="link"
        onClick={this.onResetButtonClick}
        icon={<FontAwesomeIcon icon={faXmark} />}
        title="Reset filter"
        display={this.props.filtersChanged() === true ? 'block' : 'none'}
      >
        Reset
      </IconButton>
    );
  }
}
