import React from 'react';
import './index.css';
import Project from './Templates/Project';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './Redux/ApiCall/configureStore';
import { createRoot } from 'react-dom/client';

createRoot(document.getElementById('app'))
  .render(
      <Provider store={configureStore()}>
        <Project />
      </Provider>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
