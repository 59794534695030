import React from 'react';
import { Checkbox, CheckboxGroup, Flex, Box } from '@chakra-ui/react';
import Base from './Base';
import QuickFilter from './QuickFilter';
import FilterHeader from './FilterHeader';
import { groupFilteredPlots } from '../../Services';

export default class StatusFilter extends Base {
  handleChange = (event) => {
    let selected = {};
    event.forEach((key) => {
      selected[key] = this.props.values[key];
    });

    this.props.onChange(this.props.slug, selected);
  };

  /**
   * Returns the formatted display label & plot count.
   * @param {Array} value An array containing the status slug & color.
   * @returns {string} The label to be displayed with the plot count.
   */
  generatePlotCount = (value) => {
    const statusArray = groupFilteredPlots(this.props.filteredPlots, 'status');
    const statusValue =
      statusArray[value[0].split(' ').join('-').toLowerCase()];

    return `${value[0]} (${
      statusValue != undefined || false ? statusValue.length : 0
    })`;
  };

  /**
   * Generates Status checkbox filters.
   * Each checkbox filter displays the amount of available plots that match the filter.
   * @param {Array} value An array containing the status slug & color.
   * @returns {React.Component} The checkbox filter components.
   */
  renderCheckbox = (value) => {
    return (
      <Checkbox key={value[0]} name={this.props.slug} value={value[0]}>
        <Flex align="center">
          <Box w={6} h={4} mr={2} bg={value[1]} />
          {this.generatePlotCount(value)}
        </Flex>
      </Checkbox>
    );
  };

  render() {
    return (
      <>
        <QuickFilter
          label="Direct bekijken"
          onButtonClick={this.handleChange}
          selected={Object.keys(this.props.selected)}
        />

        <FilterHeader
          label={this.props.label}
          resetCallback={this.resetCallback}
          filtersHaveChanged={this.filtersHaveChanged}
          showResetButton={true}
        />

        <CheckboxGroup
          onChange={this.handleChange}
          defaultValue={Object.keys(this.props.default)}
          value={Object.keys(this.props.selected)}
        >
          {Object.entries(this.props.values).map(this.renderCheckbox)}
        </CheckboxGroup>
      </>
    );
  }
}
