import {
  faArrowCircleLeft,
  faChevronDown,
  faChevronUp,
} from '@awesome.me/kit-2c97126699/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Divider,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeActiveLayer } from '../../Redux/ApiCall/actions';
import { isDesktop, isTouchDevice } from '../../Services';
import { findLayerById } from '../../Services/Entities/Layer';
import BreadPopup from './BreadPopup';
import BreadcrumbButton from './BreadcrumbButton';

class MobileBread extends React.Component {
  static propTypes = {
    activeLayer: PropTypes.object.isRequired,
    projectData: PropTypes.object.isRequired,
    changeActiveLayer: PropTypes.func,
    view: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onListItemClick = (layer) => {
    this.props.changeActiveLayer(
      findLayerById(this.props.projectData.layers, layer)
    );
  };

  onBackButtonClick = () => {
    const target = this.props.activeLayer.parent_id;

    if (target !== null) {
      this.onListItemClick(target);
    }
  };

  render() {
    return (
      !isDesktop &&
      this.props.view === 'map' && (
        <Popover
          position="relative"
          zIndex="2"
          arrowSize="17"
          strategy="absolute"
          placement="top-end"
          isOpen={this.state.isOpen}
          matchWidth={true}
          offset={[0, 20]}
        >
          <PopoverContent
            p="2"
            borderRadius="0"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
            width="full"
            maxHeight="76vh"
            overflowY="scroll"
          >
            <BreadPopup active={this.state.isOpen} />
            <PopoverArrow />
          </PopoverContent>

          <Box
            position="fixed"
            zIndex={2}
            left="2.5"
            bottom="2.5"
            right="2.5"
            display="flex"
            bg={'primary.bg.transparent'}
            w="auto"
            backdropFilter={'blur(4px)'}
            alignItems="stretch"
          >
            <Box display="flex" alignItems="center">
              <BreadcrumbButton
                label="Vorige"
                icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                clickCallback={() => {
                  this.onBackButtonClick();
                }}
                color={'primary.txt'}
                disabled={
                  this.props.activeLayer.id ===
                  this.props.projectData.layers[0].id
                }
              />
            </Box>

            <Divider
              orientation="vertical"
              borderColor={'primary.txt'}
              height="auto"
              my="2.5"
              opacity="0.33"
            />

            <Box
              mx={2.5}
              flex="1 1 100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Text fontSize="xs" fontWeight="bold" color={'primary.txt'}>
                Je Bekijkt Nu:
              </Text>

              <Text color={'primary.txt'}>{this.props.activeLayer.name}</Text>
            </Box>

            <Divider
              orientation="vertical"
              borderColor={'primary.txt'}
              height="auto"
              my="2.5"
              opacity="0.33"
            />

            <PopoverAnchor>
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                zIndex="-1"
              ></Box>
            </PopoverAnchor>

            <IconButton
              onClick={() =>
                this.setState({ isOpen: this.state.isOpen === false })
              }
              icon={
                <FontAwesomeIcon
                  icon={
                    this.state.isOpen === false ? faChevronUp : faChevronDown
                  }
                />
              }
              bg="transparent"
              color={'primary.txt'}
              size="lg"
              _hover={{ bg: !isTouchDevice ? 'primary.bg.darker' : null }}
              _active={{ bg: 'primary.bg.darkest' }}
              borderRadius={0}
              height={14}
            />
          </Box>
        </Popover>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  activeLayer: state.activeLayer,
  projectData: state.projectData,
});

const mapDispatchToProps = {
  changeActiveLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileBread);
