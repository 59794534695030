import React from 'react';
import PropTypes from 'prop-types';
import { slugify } from '../../Services';
import { hasSameContents } from '../../Services/Arrays/Array';

export default class Base extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    label: PropTypes.string,
    slug: PropTypes.string.isRequired,
    default: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    filteredPlots: PropTypes.array.isRequired
  };

  filtersHaveChanged = () => {
    let defaultValue = this.props.default;
    let selected = this.props.selected;

    if (typeof selected === 'object') {
      defaultValue = Object.values(this.props.selected);
      selected = Object.values(this.props.default);
      return !hasSameContents(defaultValue, selected);
    }

    return defaultValue !== selected;
  };

  resetCallback = () => {
    this.props.onChange(slugify(this.props.slug), this.props.default);
  };

  componentDidMount() {
    this.props.onChange(slugify(this.props.slug), this.props.default);
  }

  render() {
    return <></>;
  }
}
