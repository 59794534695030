import React from 'react';
import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { isTouchDevice } from "../../Services";

const BreadcrumbButton = (props) => {
  return (
    <Button
      bg={props.color}
      color={'primary.txt'}
      leftIcon={props.icon}
      onClick={props.clickCallback}
      borderRadius={0}
      backgroundColor={'transparent'}
      fontSize="xs"
      fontWeight="bold"
      px={5}
      _hover={{ bg: !isTouchDevice ? 'primary.bg.darker' : null }}
      _active={{ bg: 'primary.bg.darkest' }}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};

BreadcrumbButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  clickCallback: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BreadcrumbButton;
